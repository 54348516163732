import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const ElementsPage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Inscreva seu conteúdo"
        keywords={[`blog`, `gatsby`, `javascript`, `react`]}
      />

      <article className="post-content page-template no-image" style={{backgroundImage: "radial-gradient(#E4FFCE 5%,#E4FFCE 15%, white 60%)"}}>
        <div className="blog-body">
          
          <h1 style={{marginTop: "100px"}}>Inscreva seu conteúdo</h1>

          <p style={{fontSize: "1.25em", paddingBottom: "50px"}}>
            Nosso desejo é contar histórias não contadas antes, compartilhar rede e conhecimento, e ser um espaço de inspiração para desenvolvimento de ideias. 
            Aqui, você poderá participar remuneradamente da produção de conteúdos dentro das nossas quatro linhas editoriais. Entenda cada uma delas: <a style={{display: "inline-block !important", padding: "0", width: "auto !important", textDecoration: "underline"}} href={`/conversas`}>Conversas</a>, <a style={{display: "inline-block !important", padding: "0", width: "auto !important", textDecoration: "underline"}} href={`/hibridos`}>Híbridos</a>, <a style={{display: "inline-block !important", padding: "0", width: "auto !important", textDecoration: "underline"}} href={`/referencias`}>Referências</a> e <a style={{display: "inline-block !important", padding: "0", width: "auto !important", textDecoration: "underline"}} href={`/guias_1`}>Guias</a>
          </p>
          <a style={{marginTop: "50px", marginBottom: "50px"}} id="inscreva-button" href="https://bit.ly/compartilhada-inscreva" target="_blank">
            INSCREVA SEU CONTEÚDO
          </a>
          <h2 style={{fontSize: "1.5em", paddingTop: "50px"}}>Ei! Antes de inscrever leia com atenção abaixo:</h2>
          <p style={{fontSize: "1.25em"}}>
            <b>Assuntos de interesse:</b>
          </p>
          <p style={{fontSize: "1.25em"}}>
            Nós buscamos diversidade cultural! Por isso, na Compartilhada não temos limites, queremos saber sobre os mais diversos assuntos dentro desse universo. Cabe artes cênicas, artes visuais, artes plásticas,  audiovisual, ilustrações, música, fotografia, animação, arquitetura, design, moda, jogos, design gráfico, podcasts, literatura, publicações e, por fim, queremos saber sobre aquilo que VOCÊ cria!
          </p>
          <p style={{fontSize: "1.25em"}}>
            Procuramos por conteúdos que estejam fora dos padrões, das caixas e do que normalmente é criado. Queremos processos híbridos, diversos, complexos e estranhos. O importante é inspirar!
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Para quem:</b>
          </p>
          <p style={{fontSize: "1.25em"}}>
            Pessoas naturais ou residentes de Belo Horizonte e região metropolitana, que queiram propor e desenvolver um conteúdo dentro do segmento cultural.
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Formatos dos conteúdos:</b>
          </p>
          <p style={{fontSize: "1.25em"}}>
            Os conteúdos podem ter um ou múltiplos formatos, não queremos engessar você, por isso seu conteúdo pode ser feito em mídias diversas. Pode ser texto, vídeo, áudio, imagens, ilustrações ou qualquer outro formato digital que você queira propor.
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Sobre a remuneração:</b>
          </p>
          <p style={{fontSize: "1.25em"}}>
            Cada conteúdo, independente do formato, poderá ter a remuneração de R$300,00 (trezentos reais).
          </p>
          <p style={{fontSize: "1.25em"}}>
            Caso você queira desenvolver uma pauta que envolva mais de um criador, podemos remunerar por pessoa, dependendo do conteúdo que for desenvolvido.
          </p>
          <p style={{fontSize: "1.25em"}}>
            Depois da seleção, queremos conversar com você para ouvir mais sobre a sua ideia, entender o que será feito, definir a linha editorial e a quantidade de pautas.
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Importante:</b> Caso esteja escrevendo um conteúdo remunerado, você precisa emitir Nota Fiscal para que a gente consiga pagar. Precisamos dela para prestar contas dentro da Lei de Incentivo.
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Como vamos selecionar os conteúdos:</b>
          </p>
          <p style={{fontSize: "1.25em"}}>
            Alguns agentes da indústria criativa já foram convidados para criação de conteúdo e outras serão selecionadas a partir do chamado.
          </p>
          <p style={{fontSize: "1.25em"}}>
            Nossa vontade é selecionar todo mundo! Mas como a verba é limitada, buscamos conteúdos  que tenham propostas irreverentes e únicas. 
          </p>
          <p style={{fontSize: "1.25em"}}>
            Vamos avaliar criatividade, descentralização, diversidade de vozes e de produções artísticas/culturais. 
          </p>
          <p style={{fontSize: "1.25em"}}>
            Todos os conteúdos inscritos serão avaliados pela curadoria da plataforma e terão um retorno por e-mail dentro de 1 semana após a proposição.
          </p>
          <p style={{fontSize: "1.25em"}}>
            Preencha o formulário abaixo para fazer parte.
          </p>
          <p style={{fontSize: "1.25em"}}>
            <b>Prazo:</b>
          </p>
          <p style={{fontSize: "1.25em", paddingBottom: "50px"}}>
            A chamada ficará aberta até o dia 20/11.
            Caso o número de conteúdos selecionados atinja o teto de verba antes dessa data, podemos encerrar as inscrições antecipadamente.
          </p>
          <a style={{marginTop: "50px", marginBottom: "50px"}} id="inscreva-button" href="https://bit.ly/compartilhada-inscreva" target="_blank">
            Inscreva seu conteúdo
          </a>
          <p style={{fontSize: "1.25em", paddingTop: "50px"}}>
            Tem alguma dúvida? Mande um e-mail para: <a href="mailto:contato@evoe.cc" target="_blank">contato@evoe.cc</a>.
          </p>
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    smallPic: file(
      relativePath: { eq: "fabio-comparelli-696506-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    medPic: file(relativePath: { eq: "sophia-valkova-30139-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    largePic: file(
      relativePath: { eq: "vladimir-malyutin-98174-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (

      <ElementsPage location={props.location} data={data} {...props} />
    )}
  />
)
